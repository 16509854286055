import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { HashLink as Link } from 'react-router-hash-link';
import Menu from "./Menu";
import Order from "./Order";

const styles = theme => ({
  appBar: {
    position: "fixed",
    boxShadow: "none"
  },
  lightBar: {
    backgroundColor: "transparent"
  },
  darkBar: {
    backgroundColor: "#212529"
  },
  logoName: {
    fontFamily: "Pacifico",
    color: '#fff',
    fontWeight: 400,
    fontSize: "1.5em",
  },
  logoWrapper: {
    display: "inline",
    float: "left",
    padding: "0.5em",
    marginTop: ".75em"
  },
  flex: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-evenly"
    }
  },
  link: {
    textDecoration: "none",
    color: "#EC9524",
    "&:hover": {
      textDecoration: "none"
    }
  },
  productLogo: {
    display: "inline-block",
    borderLeft: `1px solid ${theme.palette.grey[600]}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up("md")]: {
      paddingTop: "1.5em"
    }
  },
  tagline: {
    display: "inline-block",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      paddingTop: "0.8em"
    }
  },
  iconContainer: {
    display: "none",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  iconButton: {
    float: "right",
    "&:focus": {
      outline: "none"
    }
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    float: "right"
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: "auto",
    color: "#FFFFFF",
    letterSpacing: 1,
    fontSize: "1.2em",
    fontWeight: 600,
    "&:hover": {
      color: "#EC9524",
      textDecoration: "none"
    }
  },
  btn: {
    margin: "1em",
    padding: ".5em 1em"
  }
});

class Topbar extends Component {
  state = {
    value: false,
    menuDrawer: false,
    activeIndex: 0,
    modalOpen: false
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  mobileMenuOpen = event => {
    this.setState({ menuDrawer: true });
  };

  mobileMenuClose = event => {
    this.setState({ menuDrawer: false });
  };

  orderOnMobileMenu = () => {
    this.mobileMenuClose();
    this.setState({ modalOpen: true });
  }

  nearestIndex = (
    currentPosition,
    sectionPositionArray,
    startIndex,
    endIndex
  ) => {
    
    if (startIndex === endIndex) return startIndex;
    else if (startIndex === endIndex - 1) {
      if (
        Math.abs(
          sectionPositionArray[startIndex].headerRef.current.offsetTop -
            currentPosition
        ) <
        Math.abs(
          sectionPositionArray[endIndex].headerRef.current.offsetTop -
            currentPosition
        )
      )
        return startIndex;
      else return endIndex;
    } else {
      var nextNearest = ~~((startIndex + endIndex) / 2);
      var a = Math.abs(
        sectionPositionArray[nextNearest].headerRef.current.offsetTop -
          currentPosition
      );
      var b = Math.abs(
        sectionPositionArray[nextNearest + 1].headerRef.current.offsetTop -
          currentPosition
      );
      if (a < b) {
        return this.nearestIndex(
          currentPosition,
          sectionPositionArray,
          startIndex,
          nextNearest
        );
      } else {
        return this.nearestIndex(
          currentPosition,
          sectionPositionArray,
          nextNearest,
          endIndex
        );
      }
    }
  };

  handleScroll = (e) => {
    var index = this.nearestIndex(
      window.scrollY,
      this.props.topBarData,
      0,
      this.props.topBarData.length - 1
    );
    this.setState({ activeIndex: index });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    document.addEventListener("scroll", this.handleScroll);
    return () => {
      document.removeEventListener("scroll", this.handleScroll);
    };
  }

  current = () => {
    if (this.props.currentPath === "/home-or-condo") {
      return 0;
    }
    if (this.props.currentPath === "/boxing") {
      return 1;
    }
    if (this.props.currentPath === "/bio") {
      return 2;
    }
    if (this.props.currentPath === "/rates") {
      return 3;
    }
    if (this.props.currentPath === "/contact") {
      return 4;
    }
  };

  render() {
    const { classes, navShrink, topBarData } = this.props;

    return (
      <>
        <AppBar
          position="absolute"
          color="default"
          className={`${classes.appBar} ${
            navShrink ? classes.darkBar : classes.lightBar
          }`}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="baseline">
              <Grid item xs={12}>
                <div className={classes.logoWrapper}>
                {navShrink && <Typography variant="h6" color="primary" noWrap>
                    <Link to="/" className={classes.link}>
                      <span className={classes.logoName}>Bobbie Sue's</span>
                    </Link>
                  </Typography>}
                </div>
                {!this.props.noTabs && (
                  <React.Fragment>
                    <div className={classes.iconContainer}>
                      <IconButton
                        onClick={this.mobileMenuOpen}
                        className={classes.iconButton}
                        color="inherit"
                        aria-label="Menu"
                      >
                        <MenuIcon />
                      </IconButton>
                    </div>
                    <div className={classes.tabContainer}>
                      <SwipeableDrawer
                        anchor="right"
                        open={this.state.menuDrawer}
                        onClose={this.mobileMenuClose}
                        onOpen={this.mobileMenuOpen}
                      >
                        <AppBar title="Menu" />
                        <List>
                          {Menu.map((item, index) => (
                            <ListItem
                              component={Link}
                              to={{
                                pathname: item.pathname,
                                hash: item.hash,
                                search: this.props.location.search
                              }}
                              button
                              key={item.label}
                              onClick={this.mobileMenuClose}
                            >
                              <ListItemText primary={item.label} />
                            </ListItem>
                          ))}
                            <ListItem
                              component={Link}
                              to={{
                                pathname: "",
                                search: this.props.location.search
                              }}
                              button
                              key={"Order"}
                              onClick={this.orderOnMobileMenu}
                            >
                              <ListItemText primary={"Order"} />
                            </ListItem>
                        </List>
                      </SwipeableDrawer>
                      <Tabs
                        value={this.state.activeIndex == 0 ? null : this.state.activeIndex - 1}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChange}
                      >
                        {Menu.map((item, index) => (
                          <Tab
                            key={index}
                            component={Link}
                            to={{
                              pathname: item.pathname,
                              hash: item.hash,
                              search: this.props.location.search
                            }}
                            classes={{ root: classes.tabItem }}
                            label={item.label}
                          />
                        ))}
                          <Tab
                            key={3}
                            component={Link}
                            to={{
                              pathname: "",
                              search: this.props.location.search
                            }}
                            classes={{ root: classes.tabItem }}
                            className={`${classes.btn} btn btn-primary btn-sm text-uppercase`}
                            label={"Order"}
                            onClick={() => this.setState({ modalOpen: true })}
                          />
                      </Tabs>
                    </div>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Order modalOpen={this.state.modalOpen} handleClose={() => this.setState({ modalOpen: false })}/>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(Topbar));
