import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Box, Modal, Typography, Divider } from '@mui/material';
import { Link } from "react-router-dom";
import TopBar from "./TopBar";

import classicMacPic from "../images/menu/classic.jpeg";
import pulledPorkMacPic from "../images/menu/pulled-pork.jpeg";
import bbqChickenMacPic from "../images/menu/bbq-chicken.jpeg";
import blueBuffaloChickenMacPic from "../images/menu/blue-buffalo-chicken.jpeg";
import campMacPic from "../images/menu/camp.jpeg";
import carbonaraMacPic from "../images/menu/carbonara.jpeg";
import greenTreesMacPic from "../images/menu/green-trees.jpeg";
import spicyBoiMacPic from "../images/menu/spicy-boi.jpeg";
import vaganMacPic from "../images/menu/vegan.jpeg";
import glutenFreeMacPic from "../images/menu/gluten-free.jpeg";

const styles = {
  foodMenu: {
    backgroundColor: '#0F0F0F',
    color: '#fff',
    overflow: "hidden"
  },
  grid: {
    width: 1200,
    marginTop: "4em",
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#0F0F0F'
  },
  bold: {
    fontWeight: 600
  },
  paper: {
    padding: "3rem 12%",
    fontSize: 21,
  },
  headline: {
    marginBottom: "3rem"
  },
  h1: {
    fontWeight: 800,
  },
  h2: {
    fontSize: "4.5rem",
    fontWeight: 600,
    marginBottom: "3rem"
  },
  h3: {
    fontSize: "3rem",
    fontWeight: 600,
    marginBottom: "3rem"
  },
  h4: {
    fontSize: "1.5rem",
    marginBottom: "2rem"
  },
  firstIntro: {
    position: "relative",
    width: "100%"
  },
  firstPic: {
    float: "left",
    maxWidth: "100%",
    height: "auto"
  },
  secondIntro: {
    marginTop: "8rem",
    marginBottom: "8rem"
  },
  actionButton: {
    marginTop: "3rem"
  },
  menuImage: {
    width: "100%"
  }
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

class TheMenu extends Component {
  state = {
    modalOpen: false
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    const { classes } = this.props;

    return (
      <div id="food-menu" className={classes.foodMenu}>
        <Grid container justifyContent="center">
          <Grid
            className={classes.grid}
            alignItems="center"
            justifyContent="center"
            container
            spacing={5}
          >
            <Grid item xs={12} md={10} className={classes.headline}>
              <h1 className={classes.h1}>The Menu</h1>
            </Grid> 
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <div>
                    <h2>Classic Mac</h2>
                    <p>Cavatappi pasta in house-made béchamel sauce with a five-cheese blend: mozzarella, provolone, gouda, parmesan and topped with aged cheddar.</p>
                    <p><span>SM:</span> <span>11.45</span> <span>LG:</span> <span>17.25</span></p>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                <img src={classicMacPic} alt="Classic Mac" className={classes.menuImage} />
                </Grid>
              </Grid>
              <Divider light={true} variant="fullWidth" style={{ borderColor: "rgba(255, 255, 255, 0.2)", marginTop: "2em" }}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <div>
                  <h2>Pulled Pork Mac</h2>
                  <p>Our classic mac topped with house-smoked pulled pork and BBQ sauce.</p>
                  <p><span>SM:</span> <span>15.95</span> <span>LG:</span> <span>21.75</span> <br /><br /></p>
                </div>
                </Grid>
                <Grid item xs={12} md={4}>
                <img src={pulledPorkMacPic} alt="Pulled Pork Mac" className={classes.menuImage} />
                </Grid>
              </Grid>
              <Divider light={true} variant="fullWidth" style={{ borderColor: "rgba(255, 255, 255, 0.2)", marginTop: "2em" }}/>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <div>
                    <h2>BBQ Chicken Mac</h2>
                    <p>Our classic mac topped with pulled chicken and BBQ sauce.</p>
                    <p><span>SM:</span> <span>15.55</span> <span>LG:</span> <span>21.25</span> <br /></p>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                <img src={bbqChickenMacPic} alt="BBQ Chicken Mac" className={classes.menuImage} />
                </Grid>
              </Grid>
              <Divider light={true} variant="fullWidth" style={{ borderColor: "rgba(255, 255, 255, 0.2)", marginTop: "2em" }}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={8}>
                <h3>Blue Buffalo Chicken Mac</h3>
                <p>Mac with béchamel sauce, blue cheese, chicken, buffalo sauce and topped with cheddar.</p>
                <p><span>SM:</span> <span>17.25</span> <span>LG:</span> <span>21.75</span></p>
                </Grid>
                <Grid item xs={12} md={4}>
                <img src={blueBuffaloChickenMacPic} alt="Blue Buffalo Chicken Mac" className={classes.menuImage} />
                </Grid>
              </Grid>
              <Divider light={true} variant="fullWidth" style={{ borderColor: "rgba(255, 255, 255, 0.2)", marginTop: "2em" }}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={8}>
                <h2>Camp Mac</h2>
                <p>Classic mac with beef hot dog medallions and yellow mustard.</p>
                <p><span>SM:</span> <span>13.75</span> <span>LG:</span> <span>19.45</span></p>
                </Grid>
                <Grid item xs={12} md={4}>
                <img src={campMacPic} alt="Camp Mac" className={classes.menuImage} />
                </Grid>
              </Grid>
              <Divider light={true} variant="fullWidth" style={{ borderColor: "rgba(255, 255, 255, 0.2)", marginTop: "2em" }}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={8}>
                <h2>Carbonara Mac</h2>
                <p>Classic carbonara with grana padano, pancetta and egg yolk.</p>
                <p><span>SM:</span> <span>16.25</span> <span>LG:</span> <span>22.25</span></p>
                </Grid>
                <Grid item xs={12} md={4}>
                <img src={carbonaraMacPic} alt="Carbonara Mac" className={classes.menuImage} />
                </Grid>
              </Grid>
              <Divider light={true} variant="fullWidth" style={{ borderColor: "rgba(255, 255, 255, 0.2)", marginTop: "2em" }}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={8}>
                <h2>Green Trees Mac</h2>
                <p>Our classic mac with broccoli and chili flakes.</p>
                <p><span>SM:</span> <span>13.75</span> <span>LG:</span> <span>19.45</span> <br /><br /><br /><br /><br /><br /></p>
                </Grid>
                <Grid item xs={12} md={4}>
                <img src={greenTreesMacPic} alt="Green Trees Mac" className={classes.menuImage} />
                </Grid>
              </Grid>
              <Divider light={true} variant="fullWidth" style={{ borderColor: "rgba(255, 255, 255, 0.2)", marginTop: "2em" }}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={8}>
                <h2>Vegan Mac</h2>
                <p>Made with delicious Nona sauce,  which is a dreamy blend of cashew cream and tangy cheesy flavour that is also velvety and smokey, with tasty soy "bacon" bits. <br/>Contains nuts.</p>
                <p>Nona sauce ingredients: water, soy protein, ...<Link to="" onClick={this.handleOpen}>more</Link></p>
                <p><span>SM:</span> <span>13.75</span> <span>LG:</span> <span>19.45</span></p>
                </Grid>
                <Grid item xs={12} md={4}>
                <img src={vaganMacPic} alt="Vegan Mac" className={classes.menuImage} />
                </Grid>
              </Grid>
              <Divider light={true} variant="fullWidth" style={{ borderColor: "rgba(255, 255, 255, 0.2)", marginTop: "2em" }}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={8}>
                <h2>Gluten Free Mac</h2>
                <p>Any of our macs can be made gluten free. Gluten free macs are made with corn Penne pasta and our Nona sauce which contains nuts.</p>
                Make it gluten-free <br /><p>Add: <span>SM:</span> <span>3.15</span> <span>LG:</span> <span>4.5</span></p>
                </Grid>
                <Grid item xs={12} md={4}>
                <img src={glutenFreeMacPic} alt="Gluten Free Mac" className={classes.menuImage} />
                </Grid>
              </Grid>
              <Divider light={true} variant="fullWidth" style={{ borderColor: "rgba(255, 255, 255, 0.2)", marginTop: "2em" }}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>

              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Nona Sauce Ingredients:
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                water, soy protein, concentrated carrot juice, cashews, sunflower seed oil, arrowroot powder, onion powder, garlic powder, nutritional yeast, sea salt, apple cider vinegar, paprika, xanthan gum, vegan bacon-flavoured oil [coconut oil],  torula yeast, natural hickory smoke flavour, magic], maple syrup, ascorbic acid (Vitamin C), liquid smoke.
              </Typography>
            </Box>
        </Modal>
      </div>
    );
  };
  }

export default withStyles(styles)(TheMenu);
