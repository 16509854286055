import React, { Component } from "react";
import { AppBar } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import withStyles from "@material-ui/core/styles/withStyles";
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { HashLink as Link } from 'react-router-hash-link';
import Order from "./Order";

const styles = theme => ({
  grid: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
  },
  btn: {
    margin: "1em",
    color: "#000",
  },
  btnWrapper: {
    padding: "2em !important"
  }
})

class BottomBar extends Component {
  state = {
    modalOpen: false
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid container xs={12} className={classes.grid}>
          <Grid item xs={12}>
            <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: "rgba(220,159,57,0.9)" }}>
              <Grid container spacing={2} xs={12} className={classes.grid}>
                <Grid item xs={6} className={classes.btnWrapper}>
                  <Link
                    to=""
                    onClick={() => this.setState({ modalOpen: true })}
                    className={`${classes.btn}`}
                  >
                    <StorefrontIcon /> Order
                  </Link>
                </Grid>
                <Grid item xs={6} className={classes.btnWrapper}>
                  <Link
                    to={{
                        pathname: "/",
                        hash: "location-hours"
                      }}
                    className={`${classes.btn}`}
                  >
                    <LocationOnIcon /> Location
                  </Link>
                </Grid>
              </Grid>
            </AppBar>
          </Grid>
        </Grid>
        <Order modalOpen={this.state.modalOpen} handleClose={() => this.setState({ modalOpen: false })}/>
      </>
    )
  }
}

export default withStyles(styles)(BottomBar);
