import './scss/custom.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts/Pacifico/Pacifico.ttf';
import './fonts/Signika/Signika-Regular.otf';
import './fonts/Signika/Signika-Light.otf';
import './fonts/Signika/Signika-Bold.otf';
import './fonts/Signika/Signika-Semibold.otf';

const el = document.getElementById('root');
const root = ReactDOMClient.createRoot(el);

root.render(
  <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
