import React,  { Component } from 'react';
import ReactDOM from 'react-dom'
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import cheeseBg from "../images/cheese-bg.jpeg";

const styles = theme => ({
  background: {
    backgroundImage: `url(${cheeseBg})`,
    backgroundSize: "cover"
  },
  grid: {
    maxWidth: 1200,
    marginTop: "8rem",
    marginBottom: "8rem",
    marginRight: 20,
    marginLeft: 20,
  },
  theShackImg: {
    maxWidth: "100%",
    maxHeight: "600px"
  },
  story: {
    fontSize: "1.25rem",
    padding: "2rem !important",
    textAlign: "left"
  },
  workingHours: {
    fontFamily: "motor-1",
    color: "#454545",
    lineHeight: "1.2em"
  },
  locationInfo: {
    fontSize: "0.8em"
  },
  bold: {
    fontWeight: 600
  }
});

class Location extends Component {
  state = {
    dayOfWeek: null,
  };

  componentDidMount() {
    this.calculateDayOfWeek();
  }

  calculateDayOfWeek = () => {
    const date = new Date();
    let day = date.getDay()
    this.setState({dayOfWeek: day});
  }

  render() {
    const { classes } = this.props;
    const { dayOfWeek } = this.state;

    return (
      <div id="location-hours" className={classes.background}>
        <Grid container justifyContent="center">
          <Grid
            className={classes.grid}
            alignItems="center"
            justifyContent="center"
            container
            spacing={5}
          >
            <Grid item xs={12} md={10}>
              <h1>Location & Hours</h1>
            </Grid> 
            <Grid item xs={12} md={6} className={classes.story}>
              ADDRESS
              <p className={classes.workingHours}>
                162 Ossington Ave, Unit 3 <br/>
                Toronto, ON <br/>
                <span className={classes.locationInfo}>(We're actually on Foxley street, right around the corner from Ideal Coffee)</span>
              </p>

            HOURS
              <table className={classes.workingHours} data-continuous-days="" data-continuous-days-after="" data-location-id="931">
                <tbody >
                  <tr className={dayOfWeek == 1 ? classes.bold : ''}>
                    <td>
                      Mon
                    </td>
                    <td>12:00 PM</td>
                    <td>to</td>
                    <td>8:00 PM</td>
                  </tr>
                  <tr className={dayOfWeek == 2 ? classes.bold : ''}>
                    <td>
                      Tue
                    </td>
                    <td>12:00 PM</td>
                    <td>to</td>
                    <td>8:00 PM</td>
                  </tr>
                  <tr className={dayOfWeek == 3 ? classes.bold : ''}>
                    <td>
                      Wed
                    </td>
                    <td>12:00 PM</td>
                    <td>to</td>
                    <td>8:00 PM</td>
                  </tr>
                  <tr className={dayOfWeek == 4 ? classes.bold : ''}>
                    <td>
                      Thu
                    </td>
                    <td>12:00 PM</td>
                    <td>to</td>
                    <td>8:00 PM</td>
                  </tr>
                  <tr className={dayOfWeek == 5 ? classes.bold : ''}>
                    <td>
                      Fri
                    </td>
                    <td>12:00 PM</td>
                    <td>to</td>
                    <td>10:00 PM</td>
                  </tr>
                  <tr className={dayOfWeek == 6 ? classes.bold : ''}>
                    <td>
                      Sat
                    </td>
                    <td>12:00 PM</td>
                    <td>to</td>
                    <td>10:00 PM</td>
                  </tr>
                  <tr className={dayOfWeek == 0 ? classes.bold : ''}>
                    <td>
                      Sun
                    </td>
                    <td>12:00 PM</td>
                    <td>to</td>
                    <td>08:00 PM</td>
                  </tr>
                </tbody>
              </table>

{/*            <br />
            HOLIDAY HOURS
            <p className={classes.workingHours}>
            Dec 24-27 - CLOSED <br />
            Dec 31 - 12-8pm <br />
            Jan 1 - CLOSED
            </p>*/}
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d721.750625822086!2d-79.42083815263136!3d43.648116259763825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34feb282fe6f%3A0x565c7987882a6c07!2s162%20Ossington%20Ave%2C%20Toronto%2C%20ON%20M6J%202Z7%2C%20Canada!5e0!3m2!1sen!2sus!4v1670007562489!5m2!1sen!2sus" width="100%" height="450" style={{border: "0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>  
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Location);
