import React, { Component, useRef } from "react";
import Header from "./Header";
import TopBar from "./TopBar";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import OurStory from "./OurStory";
import FoodMenu from "./FoodMenu";
import Location from "./Location";
import useTopBarData from '../hooks/useTopBarData';

const Home = (props) => {
  const topBarData = useTopBarData();

  return (
      <div>
        <TopBar navShrink={props.navShrink} topBarData={topBarData} />
        <div id="headerSection" ref={topBarData[0].headerRef}><Header  /></div>
        <div id="ourStorySection" ref={topBarData[1].headerRef}><OurStory /></div>
        <div id="foodMenuSection" ref={topBarData[2].headerRef}><FoodMenu  /></div>
        <div id="locationSection" ref={topBarData[3].headerRef}><Location /></div>
      </div>
    );
}

export default Home;
