import React, { Component } from "react";
import backgroundPicShack from "../images/bg-shack.jpeg";
import backgroundPicShack600px from "../images/bg-shack-600px.jpeg";
import backgroundPicShack900px from "../images/bg-shack-900px.jpeg";
import backgroundPicMac from "../images/bg-mac.jpeg";
import backgroundPicMac600px from "../images/bg-mac-600px.jpeg";
import backgroundPicMac900px from "../images/bg-mac-900px.jpeg";
import overlay from "../images/bg-dark-overlay.png";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { HashLink as Link } from 'react-router-hash-link';
import Order from "./Order";

const styles = theme => ({
  hero: {
    height: '130vh',
  },
  heroContent: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: theme.spacing(8, 0, 6),
    minHeight: '130vh',
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover"
    }
  },
  bg1: {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${backgroundPicMac})`,
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${backgroundPicMac600px})`,
    },
    [theme.breakpoints.down("sm")]: {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${backgroundPicMac900px})`,
    },
  },
  bg2: {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${backgroundPicShack})`,
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${backgroundPicShack600px})`,
    },
    [theme.breakpoints.down("sm")]: {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${backgroundPicShack900px})`,
    },
  },
  grid: {
    position: 'relative',
    height: '90vh'
  },
  gridItem: {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "80%"
  },
  h1: {
    fontSize: "8em",
    fontFamily: "Pacifico",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4em"
    },
    color: '#fff',
    fontWeight: 400,
    textShadow: "00px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)"
  },
  h2: {
    fontSize: "5em",
    fontFamily: "Signika",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em"
    },
    color: '#fff',
    fontWeight: 400,
    textShadow: "00px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)"
  },
  buttonGroup: {
    marginTop: "2em"
  },
  btn: {
    margin: "1em"
  }
});

class Header extends Component { 
  state = {
    modalOpen: false
  };

  render() {

    const { classes } = this.props;

    return (
      <header className={classes.hero}>
      <div className="overlay" />
        <Grid
          container
          className={classes.grid}
        >
          <Grid item xs={12} md={6} className={`${classes.heroContent} ${classes.bg1}`}>
            <Grid item xs={12} md={6} className={classes.gridItem}>
              <h1 className={classes.h1}>Bobbie Sue's</h1>
              <h2 className={classes.h2}>MAC + CHEESE</h2>
              <div className={classes.buttonGroup}>
                <Link
                  to={{
                      pathname: "",
                      hash: "food-menu"
                    }}
                  className="btn btn-primary btn-xl text-uppercase"
                >
                  See Our Menu
                </Link>

                <Link
                  to=""
                  onClick={() => this.setState({ modalOpen: true })}
                  className={`${classes.btn} btn btn-primary btn-xl text-uppercase`}
                >
                  Order
                </Link>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={0} md={6} className={`${classes.heroContent} ${classes.bg2}`}>
          </Grid>
        </Grid>
        <Order modalOpen={this.state.modalOpen} handleClose={() => this.setState({ modalOpen: false })}/>
      </header>
    );
  }
};

export default withStyles(styles)(Header);
