import { useRef } from "react";


const useTopBarData = () => {
  const headerSectionRef = useRef();
  const ourStorySectionRef = useRef();
  const foodMenuSectionRef = useRef();
  const locationSectionRef = useRef();

    const topBarData = [
    {
      headerTitle: "Header",
      headerRef: headerSectionRef,
      headerID: "headerSection"
    },
    {
      headerTitle: "Our Story",
      headerRef: ourStorySectionRef,
      headerID: "ourStorySection"
    },
    {
      headerTitle: "Menu",
      headerRef: foodMenuSectionRef,
      headerID: "foodMenuSection"
    },
    {
      headerTitle: "Location & Hours",
      headerRef: locationSectionRef,
      headerID: "locationSection"
    }
    ];

    return topBarData;
};

export default useTopBarData;
