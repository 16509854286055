import React,  { Component } from 'react';
import { Box, Grid, Modal, Typography } from '@mui/material';
import uberEatsLogo from "../images/ubereats-logo.png";
import skipTheDishesLogo from "../images/skipthedishes-logo.svg";
import doordashLogo from "../images/doordash-logo.jpeg";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Order = (props) => {
  const { classes } = props;

  return (
    <>
      <Modal
          open={props.modalOpen}
          onClose={props.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, lg: 2 }}>
              <Grid item xs={12}  style={{ borderBottom: "solid 1px", marginBottom: "1em", padding: "1em"}}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center", marginBottom: "1em", fontWeight: 600, color: "#EC9524" }}> 
                    DELIVERY
                </Typography>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={4}>
                    <a href="https://www.ubereats.com/ca/store/bobbie-sues-mac-%2B-cheese/q7PiE0nnSzCCoHYlFwqQ7Q/ffba9be0-01ce-5345-8adf-d30d8fbca2fd?diningMode=DELIVERY" target="_blank">
                      <img src={uberEatsLogo} alt="UberEats" style={{ maxWidth: "200px"}} />
                    </a>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <a href="https://www.skipthedishes.com/bobbie-sue's-ossington-avenue" target="_blank">
                      <img src={skipTheDishesLogo} alt="SkipTheDishes" style={{ maxWidth: "200px"}} />
                    </a>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <a href="https://www.doordash.com/en-CA/store/bobbie-sue's-mac-+-cheese-toronto-37532/" target="_blank">
                      <img src={doordashLogo} alt="Doordash" style={{ width: "100%", maxWidth: "200px"}}/>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            <Grid item xs={12} style={{ textAlign: "center", marginBottom: "1em" }}>
              <Typography id="modal-modal-title" variant="h6" style={{ fontWeight: 600, color: "#EC9524" }} component="h2">
                PICKUP
              </Typography>
                <a href="https://www.ubereats.com/ca/store/bobbie-sues-mac-%2B-cheese/q7PiE0nnSzCCoHYlFwqQ7Q?diningMode=PICKUP" target="_blank">
                  <img src={uberEatsLogo} alt="UberEats" style={{ maxWidth: "200px"}} />
                </a>
            </Grid>
          </Grid>

          </Box>
      </Modal>
    </>
  )
}

export default Order;
