import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import logo from "./logo.svg";
import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Faq from "./components/Faq";
import BottomBar from "./components/BottomBar";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#EC9524"
    },
    primary: {
      main: "#EC9524"
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navShrink: false
    };
  }

  componentDidMount() {
    // Adding event listener here beceause it needs the full DOM size
    window.addEventListener("scroll", event => {
      let navShrink;
      var rect = ReactDOM.findDOMNode(this).getBoundingClientRect();

      if (rect.top < -100) {
        navShrink = true;
      } else {
        navShrink = false;
      }

      this.setState({ navShrink });
    });
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <div className="App">
            <Route
              path="/"
              exact
              render={routeProps => (
                <Home {...routeProps} navShrink={this.state.navShrink} />
              )}
            />
            <Route
              path="/faq"
              exact
              render={routeProps => (
                <Faq {...routeProps} navShrink={this.state.navShrink} />
              )}
            />
            <Footer />
            <BottomBar />
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
