const Menu = [
  {
    label: "About",
    pathname: "/",
    hash: "our-story"
  },
  {
    label: "Menu",
    pathname: "/",
    hash: "food-menu"
  },
  {
    label: "Location & Hours",
    pathname: "/",
    hash: 'location-hours'
  },
  {
    label: "FAQ",
    pathname: "/faq",
  }
];

export default Menu;
