import React from "react";
import { AppBar } from '@mui/material';

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <span className="copyright">Copyright &copy; Bobbie Sue's {(new Date().getFullYear())}</span>
        </div>
        <div className="col-md-6">
{/*          <ul className="list-inline quicklinks">
            <li className="list-inline-item">
              <a href="#">Privacy Policy</a>
            </li>
            <li className="list-inline-item">
              <a href="#">Terms of Use</a>
            </li>
          </ul>*/}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
