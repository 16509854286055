import React,  { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import theShackPic from "../images/our-story.jpeg";
import cheeseBg from "../images/cheese-bg.jpeg";

const styles = theme => ({
  background: {
    backgroundImage: `url(${cheeseBg})`,
    backgroundSize: "cover"
  },
  grid: {
    width: 1200,
    marginTop: "8rem",
    marginBottom: "8rem",
    marginRight: 20,
    marginLeft: 20,
  },
  theShackImg: {
    maxWidth: "100%",
    maxHeight: "600px"
  },
  story: {
    fontSize: "1.25rem",
    padding: "2rem !important",
    textAlign: "left"
  }
});

const OurStory = (props) => {
  const { classes } = props;

  return (
    <div id="our-story" className={classes.background}>
      <Grid container justifyContent="center">
        <Grid
          className={classes.grid}
          alignItems="center"
          justifyContent="center"
          container
          spacing={5}
        >
          <Grid item xs={12} md={10}>
            <h1>Our Story</h1>
          </Grid> 
          <Grid item xs={12} md={6} className={classes.story}>
            <p>Welcome to Bobbie Sue's Mac and Cheese, a Toronto staple when it comes to comfort food.  Since opening in 2015 Bobbie Sue's iconic "hole in the wall" shack has become not just a beloved favourite for Toronto residents, but a must visit for tourists akin to the CN Tower or Casa Loma.</p>

            <p>Come try one of our scrumptious, gooey Macs baked to perfection, with a variety of delicious ingredients and creative recipes. Taste for yourself why when people think Mac and Cheese, they think Bobbie Sue's.</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={theShackPic} alt="the shack" className={classes.theShackImg} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(OurStory);
