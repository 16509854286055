import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { HashLink as Link } from 'react-router-hash-link';
import cheeseBg from "../images/cheese-bg.jpeg";
import faqBg from "../images/faq-bg.jpeg";
import faqBg600px from "../images/faq-bg-600px.jpeg";
import faqBg900px from "../images/faq-bg-900px.jpeg";
import TopBar from "./TopBar";
import useTopBarData from '../hooks/useTopBarData';

const styles = theme => ({
  hero: {
    height: '40vh',
  },
  heroContent: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: theme.spacing(8, 0, 6),
    minHeight: '40vh',
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover"
    }
  },
  bg1: {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${faqBg})`,
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${faqBg600px})`,
    },
    [theme.breakpoints.down("sm")]: {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${faqBg900px})`,
    },
  },
  grid: {
    position: 'relative',
    height: '40vh'
  },
  gridItem: {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "80%"
  },
  h2: {
    fontSize: "5em",
    fontFamily: "Signika",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em"
    },
    color: '#fff',
    fontWeight: 400,
    textShadow: "00px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)"
  },
  background: {
    backgroundImage: `url(${cheeseBg})`,
    backgroundSize: "cover"
  },
  faqGrid: {
    width: 500,
    marginTop: "8rem",
    marginBottom: "8rem",
    marginRight: 20,
    marginLeft: 20,
  }
});

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Faq = (props) => {
  const { classes } = props;

  const [expanded, setExpanded] = React.useState('panel1');
  
  const topBarData = useTopBarData();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <>
      <TopBar navShrink={props.navShrink} topBarData={topBarData} />
      <header className={classes.hero}>
      <div className="overlay" />
        <Grid
          container
          className={classes.grid}
        >
          <Grid item xs={12} className={`${classes.heroContent} ${classes.bg1}`}>
            <Grid item xs={6} className={classes.gridItem}>
              <h2 className={classes.h2}>FREQUENTLY ASKED QUESTIONS</h2>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
            </Grid>
          </Grid>
        </Grid>
      </header>
      <div className={classes.background}>
        <Grid container justifyContent="center">
          <Grid
            className={classes.faqGrid}
            alignItems="center"
            justifyContent="center"
            container
            spacing={5}
          >
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ width: "100%", textAlign: "left", background: "transparent", borderWidth: "0 0 1px 0" }}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
                <Typography><h4 style={{ color: "#EC9524", fontSize: "2em" , fontSize: "2em" }}>When are you open?</h4></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Please check our hours of operation <Link to="/#location-hours">here</Link>.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ width: "100%", textAlign: "left", background: "transparent", borderWidth: "1px 0 1px 0" }}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
                <Typography><h4 style={{ color: "#EC9524", fontSize: "2em" }}>Is there dine-in?</h4></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We are take-out only.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ width: "100%", textAlign: "left", background: "transparent", borderWidth: "1px 0 1px 0" }}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
                <Typography><h4 style={{ color: "#EC9524", fontSize: "2em" }}>What if I have allergies?</h4></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We try our best to accomondate allergies but since we have an open kitchen we cannot gaurantee our dishes are completeley allergen-free. Therefore, we advise customers err on the side of caution and avoid ordering if you have a serious allergy. Below are some options you can order:<br /><br />
                  Our <strong>regular macs</strong> are <i>nut-free</i>.<br /><br />
                  Our <strong>Vegan macs</strong> are <i>gluten-free</i> and <i>dairy free</i> and are made with a cashew based sauce and thus, <u><i>NOT nut-free.</i></u> <br /><br />
                  Our <strong>gluten-free macs</strong> are made with corn penne pasta and Nona sauce (cashew based) instead of our Béchamel sauce. <u><i>NOT nut-free.</i></u>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{ width: "100%", textAlign: "left", background: "transparent", borderWidth: "1px 0 1px 0" }}>
              <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
                <Typography><h4 style={{ color: "#EC9524", fontSize: "2em" }}>Do you provide catering?</h4></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes, we can accomondate catering orders depending on the order. Please contact us at <a href="mailto:info@bobbiesues.ca">info@bobbiesues.ca</a> for more information. <br /><br />

                  We do not have a seperate catering menu. In the past, customers ordering for a large party will simply order large macs based on the amount of people they are trying to feed. We recommend 1 large mac shared between 2 people, or if simply used as a side dish, we recommend 1 large dish per 4 people. <br /><br />

                  We do not provide delivery but customers typically can arrange an UberXL to pick up the orders from our restaurant. Please coordinate with us over email. <br /><br />

                  For large catering orders (15 macs and over), we take payment in advance and will automatically add a 20% gratuity to your bill.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{ width: "100%", textAlign: "left", background: "transparent", borderWidth: "1px 0 1px 0" }}>
              <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
                <Typography><h4 style={{ color: "#EC9524", fontSize: "2em" }}>Do you have parking?</h4></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  There is Green P parking on Ossington street and residential street parking on Foxley street, if you are lucky.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{ width: "100%", textAlign: "left", background: "transparent", borderWidth: "1px 0 1px 0" }}>
              <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
                <Typography><h4 style={{ color: "#EC9524", fontSize: "2em" }}>Do you provide delivery?</h4></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We offer delivery through UberEats, SkipTheDishes or DoorDash. 
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} style={{ width: "100%", textAlign: "left", background: "transparent", borderWidth: "1px 0 1px 0" }}>
              <AccordionSummary aria-controls="panel7d-content" id="panel7d-header" style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
                <Typography><h4 style={{ color: "#EC9524", fontSize: "2em" }}>Can I order by phone?</h4></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  No. 
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default withStyles(styles)(Faq);
